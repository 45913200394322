import { AutopromosRenderer } from "~/components/shared/Autopromos/AutopromosRenderer";

export default function AutopromoContainer() {
  return (
    <>
      <section
        class="autopromos-container single"
        id="futur-logement"
        data-ga-zone="ads"
        data-test="autopromos"
      >
        <AutopromosRenderer text="[search]" fromFieldName="field_ads" />
      </section>
    </>
  );
}
